import {RouterModule, Routes, UrlSegment} from "@angular/router";
import {SalesforceUidGuard} from "./infrastructure/guards/salesforce-uid.guard";
import {SalesforceUidDerective} from "./features/salesforce-uid/salesforce-uid.directive";
import {NgModule} from "@angular/core";
import {SalesforceUidPattern} from "./domain/model/object-info.model";

const routes: Routes = [{
  matcher: salesforceIdMatcher,
  canActivate: [SalesforceUidGuard],
  component: SalesforceUidDerective
}]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalesforceUidRoutingModule {
}

export function salesforceIdMatcher(url: UrlSegment[]): { consumed: UrlSegment[] } | null {
  if (url.length === 1 && SalesforceUidPattern.test(url[0].path)) {
    return {consumed: url};
  }
  return null;
}

import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {SalesforceUidFacade} from "../../domain/+state/salesforce-uid.facade";
import {ObjectInfo, ObjectTypePath} from "../../domain/model/object-info.model";
import {catchError, map} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {ErrorsService} from "common";

@Injectable({
  providedIn: 'root',
})
export class SalesforceUidGuard implements CanActivate {
  constructor(private router: Router,
              private facade: SalesforceUidFacade,
              private errorsService: ErrorsService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const uid = route.url[0].path;
    return this.facade.getObjectInfo(uid).pipe(
      map((objectInfo: ObjectInfo) => {
          const path = `${ObjectTypePath.get(objectInfo.type)}/${objectInfo.id}`;
          this.router.navigate([path])
          return false;
        }
      ),
      catchError((error: any) => {
        return this.handleError(error);
      })
    )
  }

  private handleError(error: any): Observable<boolean> {
    switch (error?.status) {
      case 403:
        this.errorsService.showForbiddenErrorPage();
        break;
      case 404:
        this.errorsService.showNotFoundErrorPage();
        break;
      default:
        this.errorsService.showGeneralErrorPage();
        break;
    }
    return of(false);
  }
}


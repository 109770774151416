import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "common";
import {Observable} from "rxjs";
import {ObjectInfo} from "../domain/model/object-info.model";

@Injectable({
  providedIn: "root"
})
export class SalesforceUidRemote {
  private readonly salesforceUidUrl = `${this.configService.environment.salesforceUidResolver?.url}/salesforceidresolver`;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {
  }

  get(uid: string): Observable<ObjectInfo> {
    return this.http.get<ObjectInfo>(`${this.salesforceUidUrl}/${uid}`);
  }
}

export interface ObjectInfo {
  id: number;
  type: ObjectType;
}

export enum ObjectType {
  Application = 'application',
}

export const ObjectTypePath = new Map<string, string>([
  [ObjectType.Application, "submission"],
]);

export const SalesforceUidPattern = /^[A-Za-z0-9]{15,18}$/;

import {Injectable} from "@angular/core";
import {SalesforceUidRemote} from "../../infrastructure/salesforce-uid.remote";
import {ObjectInfo} from "../model/object-info.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class SalesforceUidFacade {
  constructor(private readonly remote: SalesforceUidRemote) {}

  getObjectInfo(uid: string): Observable<ObjectInfo> {
    return this.remote.get(uid);
  }
}
